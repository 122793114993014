import { User } from "oidc-client";
import UserProfile from "../../UserProfile";
export default class OidcDevelopmentService {
    get hostname() {
        return process.env.VUE_APP_HOSTNAME;
    }
    get authority() {
        return process.env.VUE_APP_OIDC_AUTHORITY_OKTA;
    }
    get clientId() {
        return process.env.VUE_APP_OIDC_CLIENT_ID_OKTA;
    }
    get redirectUri() {
        return this.hostname + process.env.VUE_APP_OIDC_REDIRECT_URI;
    }
    get automaticSilentRenew() {
        return Boolean(process.env.VUE_APP_OIDC_SILENT_RENEW);
    }
    get silentRedirectUri() {
        return this.hostname + process.env.VUE_APP_OIDC_SILENT_RENEW_URI;
    }
    get postLogoutRedirectUri() {
        return process.env.VUE_APP_OIDC_POST_LOGOUT_REDIRECT_URI;
    }
    get scope() {
        return process.env.VUE_APP_OIDC_SCOPE;
    }
    get metadataUrl() {
        return process.env.VUE_APP_OIDC_METADATA_URL_OKTA;
    }
    get clientSecret() {
        console.log("Secret: " + process.env.VUE_APP_OIDC_CLIENT_SECRET_COGNITO);
        return process.env.VUE_APP_OIDC_CLIENT_SECRET_COGNITO;
    }
    constructor() {
        this.loggedIn = true;
    }
    async getUser() {
        return new User(null);
    }
    async silentLogin(attemptedPath) {
        return null;
    }
    async getUserProfile() {
        let userProfile = new UserProfile();
        userProfile.email = "david.light@sunlightsolutions.io";
        userProfile.firstName = "David";
        userProfile.lastName = "Light";
        userProfile.groups.push('Everyone');
        userProfile.groups.push('accounting');
        return userProfile;
    }
    async login() {
        this.loggedIn = true;
    }
    async logout() {
        this.loggedIn = false;
    }
    async logoutCallback() {
        this.loggedIn = false;
    }
    async getAccessToken() {
        let accessToken = "";
        return accessToken;
    }
    async isLoggedIn() {
        return this.loggedIn;
    }
    async silentRefresh() {
    }
    async getRoles() {
        let roles = [];
        roles.push('Everyone');
        roles.push('accounting');
        return roles;
    }
    async accessAllowed(roles) {
        console.log('Inside accessAllowed: ' + JSON.stringify(roles));
        let allowed = false;
        if (await this.isLoggedIn() === true) {
            if (roles != null && roles.length > 0) {
                let userRoles = await this.getRoles();
                if (userRoles != null) {
                    for (let roleIdx = 0; roleIdx < userRoles.length; roleIdx++) {
                        let userRole = userRoles[roleIdx];
                        if (roles.indexOf(userRole) > -1) {
                            allowed = true;
                        }
                    }
                }
            }
            else {
                allowed = true;
            }
        }
        console.log('Returning accessAllowed: ' + JSON.stringify(allowed));
        return allowed;
    }
    async getSessionActive() {
        return null;
    }
    async signinRedirectCallback() {
    }
}
