import Vue from 'vue';
import Vuex from 'vuex';
import { set, toggle } from "./vuex-utils";
import ContentManagementApiClient from '@/services/api/ContentManagementApiClient';
Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        layout: "non-authenticated-layout",
        drawer: false,
        color: "success",
        mini: true,
        searchResults: null,
        featuredFolders: null,
        cmsClient: new ContentManagementApiClient(process.env.VUE_APP_API_CMS_BASEURL)
    },
    mutations: {
        SET_LAYOUT(state, payload) {
            state.layout = payload;
        },
        SET_DRAWER(state, payload) {
            state.drawer = payload;
        },
        setDrawer: set("drawer"),
        setMini: set("mini"),
        toggleMini: toggle("mini"),
        setImage: set("image"),
        setColor: set("color"),
        toggleDrawer: toggle("drawer"),
        setSearchResults: set("searchResults"),
        setFeaturedFolders: set("featuredFolders")
    },
    getters: {
        layout(state) {
            return state.layout;
        },
        activeSearchResults(state) {
            return state.searchResults;
        },
        activeFeaturedFolders(state) {
            return state.featuredFolders;
        }
    },
    actions: {
        async searchDocuments({ commit, state }, searchTerm) {
            const results = await state.cmsClient.getSearchResults(searchTerm);
            if (results && results.length > 0) {
                commit("setSearchResults", results);
            }
            else {
                commit("setSearchResults", null);
            }
        },
        async retrieveFeaturedFolders({ commit, state }) {
            const folders = await state.cmsClient.getFeaturedFolders();
            if (folders && folders.length > 0) {
                commit("setFeaturedFolders", folders);
            }
            else {
                commit("setFeaturedFolders", null);
            }
        },
        async getFeaturedContents({ commit, state }, folderID) {
            const documents = await state.cmsClient.getFolderDocuments(folderID);
            if (documents && documents.length > 0) {
                commit("setSearchResults", documents);
            }
            else {
                commit("setSearchResults", null);
            }
        }
    },
    modules: {}
});
