// https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
export function humanFileSize(bytes, decimals = 2, si = true) {
    var thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    var units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    var u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(decimals) + ' ' + units[u];
}
// https://stackoverflow.com/questions/4212861/what-is-a-correct-mime-type-for-docx-pptx-etc
export function mapContentTypeToIcon(documentContentType, documentName) {
    switch (documentContentType) {
        case "application/msword":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
        case "application/vnd.ms-word.document.macroEnabled.12":
        case "application/vnd.ms-word.template.macroEnabled.12":
            return "far fa-file-word";
        case "application/vnd.ms-excel":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
        case "application/vnd.ms-excel.sheet.macroEnabled.12":
        case "application/vnd.ms-excel.template.macroEnabled.12":
        case "application/vnd.ms-excel.addin.macroEnabled.12":
        case "application/vnd.ms-excel.sheet.binary.macroEnabled.12":
            return "far fa-file-excel";
        case "application/vnd.ms-powerpoint":
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        case "application/vnd.openxmlformats-officedocument.presentationml.template":
        case "application/vnd.openxmlformats-officedocument.presentationml.slideshow":
        case "application/vnd.ms-powerpoint.addin.macroEnabled.12":
        case "application/vnd.ms-powerpoint.presentation.macroEnabled.12":
        case "application/vnd.ms-powerpoint.template.macroEnabled.12":
        case "application/vnd.ms-powerpoint.slideshow.macroEnabled.12":
            return "far fa-file-powerpoint";
        case "application/vnd.ms-access":
            return "far fa-file-alt";
        case "application/pdf":
            return "far fa-file-pdf";
        case "text/plain":
            return "fas fa-external-link-alt";
        default:
            // Fallback to the document extension if the content type isn't found
            if (documentName != null) {
                const documentExtension = (documentName.substring(documentName.lastIndexOf(".") + 1)).toLowerCase();
                switch (documentExtension) {
                    case "zip":
                    case "rar":
                        return "fa fa-file-archive";
                    case "htm":
                    case "html":
                    case "js":
                    case "json":
                        return "fa fa-file-code";
                    case "pdf":
                        return "far fa-file-pdf";
                    case "png":
                    case "jpg":
                    case "jpeg":
                        return "far fa-file-image";
                    case "mp4":
                    case "mkv":
                    case "avi":
                    case "wmv":
                    case "mov":
                        return "far fa-file-video";
                    case "md":
                    case "txt":
                        return "far fa-file-alt";
                    case "csv":
                        return "far fa-file-excel";
                    case "other":
                    default:
                        return "far fa-file";
                }
            }
            return "far fa-file";
    }
}
// Alternate list of icons to map to based on doucment extension
// this.icons = {
//   zip: "mdi-folder-zip-outline",
//   rar: "mdi-folder-zip-outline",
//   htm: "mdi-language-html5",
//   html: "mdi-language-html5",
//   js: "mdi-nodejs",
//   json: "mdi-json",
//   md: "mdi-markdown",
//   pdf: "mdi-file-pdf",
//   png: "mdi-file-image",
//   jpg: "mdi-file-image",
//   jpeg: "mdi-file-image",
//   mp4: "mdi-filmstrip",
//   mkv: "mdi-filmstrip",
//   avi: "mdi-filmstrip",
//   wmv: "mdi-filmstrip",
//   mov: "mdi-filmstrip",
//   txt: "mdi-file-document-outline",
//   xls: "mdi-file-excel",
//   other: "mdi-file-outline"
// };
export function mapContentTypeToIconView(documentContentType, documentName) {
    switch (documentContentType) {
        case "text/plain":
            return 'fas fa-external-link-alt';
        default:
            return 'fas fa-file-download';
    }
}
export function mapContentTypeToTooltip(documentContentType, documentName) {
    switch (documentContentType) {
        case 'text/plain':
            return 'View';
        default:
            return 'Download';
    }
}
export function counting(folder) {
    let totalDocumentCount = folder.DocumentCount.valueOf();
    for (const child of folder.children) {
        totalDocumentCount += counting(child);
    }
    return totalDocumentCount;
}
