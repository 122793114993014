export default class UserProfile {
    constructor() {
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.groups = [];
    }
    hydrateFromOidcUser(user) {
        this.firstName = user.profile.given_name;
        this.lastName = user.profile.family_name;
        this.email = user.profile.email;
        this.groups = user.profile.groups[0].split(',');
    }
}
