import * as tslib_1 from "tslib";
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import OidcService from '@/services/authentication/OIDC/OidcService';
let SilentRenewView = class SilentRenewView extends Vue {
    constructor() {
        super();
        this.authService = new OidcService();
    }
    async mounted() {
        try {
            await this.authService.silentRefresh();
            console.log("Refreshed token");
        }
        catch (error) {
            console.log("Failed to renew token. " + error.message);
        }
    }
};
SilentRenewView = tslib_1.__decorate([
    Component({
        name: 'SilentRenew',
        components: {}
    })
], SilentRenewView);
export default SilentRenewView;
