import axios from "axios";
import { JsonConvert, OperationMode, ValueCheckingMode } from "json2typescript";
import { ApiUtilities } from './ApiUtilities';
let jsonConvert = new JsonConvert();
jsonConvert.operationMode = OperationMode.ENABLE;
jsonConvert.ignorePrimitiveChecks = false;
jsonConvert.valueCheckingMode = ValueCheckingMode.DISALLOW_NULL;
export default class ContentManagementApiClient {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }
    async bodyRequest(url, verb, data, headers) {
        let apiResult;
        try {
            const requestHeaders = {
                ...ApiUtilities.getDefaultHeaders(),
                ...await ApiUtilities.getAuthenticationHeaders(),
                ...headers,
            };
            apiResult = await axios[verb](url, data, { headers: requestHeaders });
        }
        catch (error) {
            throw new Error("Call to API failed. Error: " + error.message);
        }
        let txnResponse;
        if (apiResult.data.responseCode === 200) {
            txnResponse = apiResult.data;
        }
        else if (apiResult.data.responseCode === 401 || apiResult.data.responseCode === 500) {
            throw new Error("Internal API unauthorized error. Error: " + apiResult.data.message);
        }
        else if (apiResult.data.responseCode === 404) {
            throw new Error("Internal API not found error. Error: " + apiResult.data.message);
        }
        else {
            throw new Error("Internal API error. Error: " + apiResult.data.message);
        }
        return txnResponse;
    }
    async noBodyRequest(url, verb, headers, params) {
        let apiResult;
        try {
            const requestHeaders = {
                ...ApiUtilities.getDefaultHeaders(),
                ...await ApiUtilities.getAuthenticationHeaders(),
                ...headers,
            };
            apiResult = await axios[verb](url, { headers: requestHeaders, params });
        }
        catch (error) {
            throw new Error("Call to API failed. Error: " + error.message);
        }
        if (apiResult.status !== 200) {
            throw new Error("API Result failed. Error: " + apiResult.statusText);
        }
        let txnResponse;
        if (apiResult.data.responseCode === 200) {
            txnResponse = apiResult.data;
        }
        else if (apiResult.data.responseCode === 401 || apiResult.data.responseCode === 500) {
            throw new Error("Internal API unauthorized error. Error: " + apiResult.data.message);
        }
        else if (apiResult.data.responseCode === 404) {
            throw new Error("Internal API not found error. Error: " + apiResult.data.message);
        }
        else {
            throw new Error("Internal API error. Error: " + apiResult.data.message);
        }
        return txnResponse;
    }
    async postRequest(url, data) {
        return this.bodyRequest(url, "post", data);
    }
    async postFormRequest(url, data) {
        return this.bodyRequest(url, "post", data, {
            'Content-Type': 'multipart/form-data',
        });
    }
    async putRequest(url, data) {
        return this.bodyRequest(url, "put", data);
    }
    async getRequest(url, params) {
        return this.noBodyRequest(url, "get", null, params);
    }
    async deleteRequest(url) {
        return this.noBodyRequest(url, "delete");
    }
    async getFolderNavigation() {
        const requestUrl = `${this.baseUrl}/cms/navigation`;
        const folderNavResponse = await this.getRequest(requestUrl);
        return folderNavResponse.folder;
    }
    async getFolderProperties(folderId) {
        const requestUrl = `${this.baseUrl}/cms/folder/${folderId}/properties`;
        const folderPropResponse = await this.getRequest(requestUrl);
        return {
            ...folderPropResponse.folder,
        };
    }
    async getFolderDocuments(folderId, recursive) {
        const requestUrl = `${this.baseUrl}/cms/folder/${folderId}/documents`;
        const folderPropResponse = await this.getRequest(requestUrl, { recursive });
        return folderPropResponse.documents.map(doc => {
            return {
                ...doc,
            };
        });
    }
    async addFolder(newFolderProperties) {
        const requestUrl = `${this.baseUrl}/cms/folder`;
        const folderPropResponse = await this.postRequest(requestUrl, newFolderProperties);
        return {
            ...folderPropResponse.folder,
        };
    }
    async removeFolder(folderId) {
        const requestUrl = `${this.baseUrl}/cms/folder/${folderId}`;
        const folderDeleteResponse = await this.deleteRequest(requestUrl);
    }
    async updateFolderProperties(folderId, folderProperties) {
        const requestUrl = `${this.baseUrl}/cms/folder/${folderId}/properties`;
        const folderPropResponse = await this.putRequest(requestUrl, folderProperties);
        return {
            ...folderPropResponse.folder,
        };
    }
    async getDocumentProperties(documentId) {
        const requestUrl = `${this.baseUrl}/cms/documents/${documentId}/properties`;
        const documentPropResponse = await this.getRequest(requestUrl);
        return {
            ...documentPropResponse.document
        };
    }
    async uploadDocument(folderId, file) {
        const requestUrl = `${this.baseUrl}/cms/folders/${folderId}/documents`;
        const formData = new FormData();
        formData.append('file', file);
        const documentUploadResponse = await this.postFormRequest(requestUrl, formData);
    }
    async removeDocument(documentId) {
        const requestUrl = `${this.baseUrl}/cms/documents/${documentId}`;
        const documentDeleteResponse = await this.deleteRequest(requestUrl);
    }
    async updateDocumentProperties(folderId, fileProperties) {
        const requestUrl = `${this.baseUrl}/cms/documents/${folderId}/properties`;
        const documentPropResponse = await this.putRequest(requestUrl, fileProperties);
        return {
            ...documentPropResponse.document,
        };
    }
    async setActiveDocumentVersion(versiondId) {
        return this.updateDocumentVersionProperties(versiondId, {
            current: true
        });
    }
    async updateDocumentVersionProperties(versiondId, documentVersionProperties) {
        const requestUrl = `${this.baseUrl}/cms/versions/${versiondId}`;
        const versionPropResponse = await this.putRequest(requestUrl, documentVersionProperties);
        return versionPropResponse.documentVersion;
    }
    async getDocumentPermissions(documentId) {
        const requestUrl = `${this.baseUrl}/cms/documents/${documentId}/permissions`;
        const documentPermissionsResponse = await this.getRequest(requestUrl);
        return documentPermissionsResponse.permissions;
    }
    async addDocumentViewPermission(documentId, roleName) {
        const requestUrl = `${this.baseUrl}/cms/documents/${documentId}/permissions`;
        const documentPermissionsResponse = await this.postRequest(requestUrl, {
            roleName,
            canView: true,
            canDownload: true,
            canUpdate: false,
            canDelete: false,
            canManagePermissions: false,
            canManageVersions: false,
            isLocked: false,
        });
        return documentPermissionsResponse.permissions[0];
    }
    async removePermission(permissionId) {
        const requestUrl = `${this.baseUrl}/cms/permissions/${permissionId}`;
        await this.deleteRequest(requestUrl);
    }
    async getPermissionGroups() {
        const requestUrl = `${this.baseUrl}/cms/groups`;
        const groupsResponse = await this.getRequest(requestUrl);
        return groupsResponse.groups;
    }
    async getSearchResults(searchTerm) {
        const requestUrl = `${this.baseUrl}/cms/documents/search?query="${searchTerm}"`;
        const searchResponse = await this.getRequest(requestUrl);
        return searchResponse.documents.map(doc => {
            return {
                ...doc
            };
        });
    }
    async getFeaturedFolders() {
        const requrestUrl = `${this.baseUrl}/cms/folders/featured`;
        const featuredResponse = await this.getRequest(requrestUrl);
        return featuredResponse.featuredFolders;
    }
    async downloadFile(documentId, documentFilename) {
        const url = `${this.baseUrl}/cms/documents/${documentId}/download`;
        const requestHeaders = {
            ...ApiUtilities.getDefaultHeaders(),
            ...await ApiUtilities.getAuthenticationHeaders()
        };
        const response = await axios.get(url, { responseType: 'blob', headers: requestHeaders });
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', documentFilename);
        document.body.appendChild(fileLink);
        fileLink.click();
    }
    async addLink(folderId, data) {
        const requestUrl = `${this.baseUrl}/cms/folders/${folderId}/addLink`;
        const formData = new FormData();
        formData.append('url', data.url);
        formData.append('title', data.title);
        formData.append('description', data.description);
        formData.append('targetBlank', data.targetBlank + '');
        const response = await this.postFormRequest(requestUrl, formData);
        return response;
    }
    async addFolderViewPermission(folderId, roleName) {
        const requestUrl = `${this.baseUrl}/cms/folder/permissions/${folderId}`;
        const folderPermissionsResponse = await this.postRequest(requestUrl, {
            roleName,
            canView: true,
            canDownload: true,
            canUpdate: false,
            canDelete: false,
            canManagePermissions: false,
            canAddDocument: false,
            canAddFolder: false,
            isLocked: false,
        });
        return folderPermissionsResponse.permissions[0];
    }
    async removeFolderPermission(permissionId) {
        const requestUrl = `${this.baseUrl}/cms/folder/permissions/${permissionId}`;
        await this.deleteRequest(requestUrl);
    }
    async getFolderPermissions(folderId) {
        const requestUrl = `${this.baseUrl}/cms/folder/${folderId}/permissions`;
        const documentPermissionsResponse = await this.getRequest(requestUrl);
        return documentPermissionsResponse.permissions;
    }
}
