import * as tslib_1 from "tslib";
import Vue from "vue";
import { Component } from 'vue-property-decorator';
let AppView = class AppView extends Vue {
    constructor() {
        super();
    }
    get layout() {
        return (this.$route.meta.layout || 'no-sidebar') + '-layout';
    }
};
AppView = tslib_1.__decorate([
    Component({
        components: {}
    })
], AppView);
export default AppView;
