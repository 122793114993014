import * as tslib_1 from "tslib";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { State, Mutation } from "vuex-class";
import AuthServiceFactory from "@/services/authentication/AuthServiceFactory";
let SideDrawerView = class SideDrawerView extends Vue {
    constructor() {
        super();
        this.authService = AuthServiceFactory.GetAuthService();
        this.isLoggedIn = false;
        this.user = null;
        this.mini = true;
        this.applications = [
            {
                text: "My Content Portal",
                icon: "fas fa-book",
                roles: ["everyone"],
                links: [
                    {
                        to: "/search",
                        icon: "fas fa-search",
                        text: "Search",
                        external: false,
                        roles: ["everyone"]
                    }
                ]
            },
            {
                text: "Administration",
                icon: "fas fa-user-lock",
                roles: [
                    "GlobalAdministrator",
                    "ContentAdministrator",
                    "ContentContributor"
                ],
                links: [
                    {
                        to: "/admin/file-manager",
                        icon: "fas fa-file",
                        text: "Manage Content",
                        external: false,
                        roles: [
                            "GlobalAdministrator",
                            "ContentAdministrator",
                            "ContentContributor"
                        ]
                    }
                ]
            }
        ];
    }
    get drawerState() {
        return this.$store.state.drawer;
    }
    get myApplications() {
        let myApps = new Array();
        for (let appIdx = 0; appIdx < this.applications.length; appIdx++) {
            let app = this.applications[appIdx];
            if (app.roles != undefined && app.roles.length > 0) {
                for (let roleIdx = 0; roleIdx < app.roles.length; roleIdx++) {
                    if (app.roles[roleIdx] === "everyone") {
                        myApps.push(app);
                    }
                    else if (this.user != null &&
                        this.user.profile.groups.indexOf(app.roles[roleIdx]) > -1) {
                        myApps.push(app);
                        break;
                    }
                }
            }
            else {
                myApps.push(app);
            }
        }
        return myApps;
    }
    async mounted() {
        this.isLoggedIn = await this.authService.isLoggedIn();
        this.user = await this.authService.getUser();
    }
    set drawerState(value) {
        this.setDrawer(value);
    }
    get mini() {
        return this.$store.state.mini;
    }
    set mini(value) {
        this.setMini(value);
    }
    aileronRedirect(event) {
        window.location.href = "https://www.aileron.org/";
    }
};
tslib_1.__decorate([
    Prop({ type: Boolean, default: false })
], SideDrawerView.prototype, "opened", void 0);
tslib_1.__decorate([
    State("image")
], SideDrawerView.prototype, "image", void 0);
tslib_1.__decorate([
    State("color")
], SideDrawerView.prototype, "color", void 0);
tslib_1.__decorate([
    Mutation("setDrawer")
], SideDrawerView.prototype, "setDrawer", void 0);
tslib_1.__decorate([
    Mutation("toggleDrawer")
], SideDrawerView.prototype, "toggleDrawer", void 0);
tslib_1.__decorate([
    Mutation("setMini")
], SideDrawerView.prototype, "setMini", void 0);
tslib_1.__decorate([
    Mutation("toggleMini")
], SideDrawerView.prototype, "toggleMini", void 0);
SideDrawerView = tslib_1.__decorate([
    Component({
        name: "SideDrawer",
        components: {}
    })
], SideDrawerView);
export default SideDrawerView;
