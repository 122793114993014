import * as tslib_1 from "tslib";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import SideDrawer from "@/components/core/SideDrawer.vue";
import Toolbar from "@/components/core/Toolbar.vue";
let MaterialLayoutView = class MaterialLayoutView extends Vue {
    constructor() {
        super();
    }
};
MaterialLayoutView = tslib_1.__decorate([
    Component({
        name: "MaterialLayout",
        components: {
            SideDrawer,
            Toolbar,
        }
    })
], MaterialLayoutView);
export default MaterialLayoutView;
