import * as tslib_1 from "tslib";
import Vue from "vue";
import { Component } from "vue-property-decorator";
let FeaturedItem = class FeaturedItem extends Vue {
};
FeaturedItem = tslib_1.__decorate([
    Component({
        props: ["item"]
    })
], FeaturedItem);
export default FeaturedItem;
