import * as tslib_1 from "tslib";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { humanFileSize, mapContentTypeToIcon } from "@/views/admin/DocumentHelpers";
import ContentManagementApiClient from "../../services/api/ContentManagementApiClient";
import AuthServiceFactory from "@/services/authentication/AuthServiceFactory";
let DocumentResults = class DocumentResults extends Vue {
    constructor() {
        super(...arguments);
        this.loadingDocument = true;
        this.documentFound = false;
        this.documentAvaliable = false;
        this.isLoggedIn = false;
    }
    async mounted() {
        const authService = AuthServiceFactory.GetAuthService();
        this.isLoggedIn = await authService.isLoggedIn();
        this.cmsClient = new ContentManagementApiClient(process.env.VUE_APP_API_CMS_BASEURL);
        this.getDocument();
    }
    async getDocument() {
        this.loadingDocument = true;
        try {
            this.cmsDocument = await this.cmsClient.getDocumentProperties(this.$route.params.id);
            this.documentFound = true;
            this.documentAvaliable = true;
        }
        catch (ex) {
            if (ex.message.includes("not found")) {
                this.documentFound = false;
                this.documentAvaliable = false;
            }
            else if (ex.message.includes("unauthorized")) {
                this.documentFound = true;
                this.documentAvaliable = false;
            }
            else {
                this.documentFound = false;
                this.documentAvaliable = false;
            }
        }
        if (this.documentFound && !this.documentAvaliable && !this.isLoggedIn) {
            const authService = AuthServiceFactory.GetAuthService();
            console.log(this.$route.path);
            authService.login(this.$route.path);
        }
        this.loadingDocument = false;
    }
    searchTermChanged(newSearchTerm) {
        this.$data.searchTerm = newSearchTerm;
    }
    searchClicked() {
        if (this.$data.searchTerm) {
            this.$router.push({ path: `/search/results?q=${this.$data.searchTerm}` });
        }
    }
    async downloadFile(result) {
        await this.cmsClient.downloadFile(result.id, result.fileName);
    }
};
DocumentResults = tslib_1.__decorate([
    Component({
        name: "DocumentResults",
        data: () => ({
            searchTerm: "",
            cmsDocument: null
        }),
        filters: {
            formatBytes(bytes) {
                return humanFileSize(bytes);
            },
            documentIconFilter(document) {
                return mapContentTypeToIcon(document.contentType, document.fileName);
            }
        }
    })
], DocumentResults);
export default DocumentResults;
