import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store/CMSStore';
import vuetify from './plugins/vuetify';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import JsonExcel from "vue-json-excel";
Vue.component('downloadExcel', JsonExcel);
Vue.config.productionTip = false;
Vue.component('font-awesome-icon', FontAwesomeIcon);
library.add(faUserSecret);
library.add(fas);
library.add(far);
/* Import layouts here */
import NoSidebarLayout from '@/layouts/NoSidebarLayout.vue';
import SidebarLayout from '@/layouts/SidebarLayout.vue';
import MaterialLayout from '@/layouts/MaterialLayout.vue';
import SearchLayout from '@/layouts/SearchLayout.vue';
Vue.component('no-sidebar-layout', NoSidebarLayout);
Vue.component('sidebar-layout', SidebarLayout);
Vue.component('material-layout', MaterialLayout);
Vue.component('search-layout', SearchLayout);
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');
