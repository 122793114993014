import { UserManager, WebStorageStateStore } from "oidc-client";
import { setCookie } from '@/services/CookieHelpers';
export default class OidcService {
    get hostname() {
        return process.env.VUE_APP_HOSTNAME;
    }
    get authority() {
        return process.env.VUE_APP_OIDC_AUTHORITY;
    }
    get clientId() {
        return process.env.VUE_APP_OIDC_CLIENT_ID;
    }
    get clientSecret() {
        return process.env.VUE_APP_OIDC_CLIENT_SECRET;
    }
    get redirectUri() {
        return "https://" + location.host + process.env.VUE_APP_OIDC_REDIRECT_URI;
    }
    get automaticSilentRenew() {
        return Boolean(process.env.VUE_APP_OIDC_SILENT_RENEW);
    }
    get silentRedirectUri() {
        return this.hostname + process.env.VUE_APP_OIDC_SILENT_RENEW_URI;
    }
    get postLogoutRedirectUri() {
        return process.env.VUE_APP_OIDC_POST_LOGOUT_REDIRECT_URI;
    }
    get scope() {
        return process.env.VUE_APP_OIDC_SCOPE;
    }
    get metadataUrl() {
        return process.env.VUE_APP_OIDC_METADATA_URL;
    }
    get responseType() {
        return process.env.VUE_APP_OIDC_RESPONSE_TYPE;
    }
    constructor() {
        this.oidcSettings = {
            userStore: new WebStorageStateStore({
                store: window.sessionStorage
            }),
            authority: this.authority,
            client_id: this.clientId,
            client_secret: this.clientSecret,
            redirect_uri: this.redirectUri,
            automaticSilentRenew: this.automaticSilentRenew,
            silent_redirect_uri: this.silentRedirectUri,
            response_type: this.responseType,
            scope: this.scope,
            post_logout_redirect_uri: this.postLogoutRedirectUri,
            filterProtocolClaims: true,
            loadUserInfo: true,
            metadataUrl: this.metadataUrl
        };
        this.userManager = new UserManager(this.oidcSettings);
        this.userManager.events.addUserLoaded(this.addUserEventHandler);
        this.userManager.events.addAccessTokenExpiring(this.accessTokenExpiringEventHandler);
        this.userManager.events.addAccessTokenExpired(this.accessTokenExpiredEventHandler);
        this.userManager.events.addSilentRenewError(this.addSilentRenewErrorEventHandler);
        this.userManager.events.addUserSignedOut(this.addUserSignedOutEventHandler);
        this.userManager.clearStaleState();
    }
    addUserEventHandler() {
        console.log("User was just added");
    }
    accessTokenExpiringEventHandler() {
        console.log("User token is expiring");
    }
    async accessTokenExpiredEventHandler() {
        if (this.userManager != null) {
            this.userManager.clearStaleState();
            await this.userManager.signinRedirect();
        }
    }
    addSilentRenewErrorEventHandler(error) {
        console.log("A silent renew error just occurred. Error: " + error);
    }
    addUserSignedOutEventHandler() {
        console.log("User was signed out");
    }
    async getUser() {
        let user = await this.userManager.getUser();
        return user;
    }
    async getSessionActive() {
        return await this.userManager.querySessionStatus();
    }
    async login(attemptedPath) {
        let client_id = process.env.VUE_APP_OIDC_CLIENT_ID;
        if (attemptedPath) {
            setCookie("attemptedPath", attemptedPath, 1);
            return this.userManager.signinRedirect({
                extraQueryParams: {
                    attemptedPath
                }
            });
        }
        else {
            return this.userManager.signinRedirect();
        }
    }
    async silentLogin(attemptedPath) {
        let user = await this.userManager.getUser();
        if (user != null) {
            await this.login(attemptedPath);
        }
        return user;
    }
    async logout() {
        let client_id = process.env.VUE_APP_OIDC_CLIENT_ID;
        return await this.userManager.signoutRedirect({
            extraQueryParams: {
                client_id
            }
        });
    }
    async logoutCallback() {
        return this.userManager.signoutRedirectCallback(this.postLogoutRedirectUri);
    }
    async getAccessToken() {
        let accessToken = "";
        let user = await this.getUser();
        if (user != null) {
            accessToken = user.access_token;
        }
        return accessToken;
    }
    async isLoggedIn() {
        const user = await this.getUser();
        return user !== null && !user.expired;
    }
    async silentRefresh() {
        await this.userManager.signinSilentCallback();
    }
    async getRoles() {
        let user = await this.getUser();
        if (user !== null && !user.expired) {
            return user.profile.groups;
        }
        else {
            await this.login();
        }
    }
    async accessAllowed(roles) {
        let allowed = false;
        if (roles != null && roles.length > 0) {
            let userRoles = await this.getRoles();
            if (userRoles != null) {
                for (let roleIdx = 0; roleIdx < userRoles.length; roleIdx++) {
                    let userRole = userRoles[roleIdx];
                    if (roles.indexOf(userRole) > -1) {
                        allowed = true;
                    }
                }
            }
        }
        else {
            allowed = true;
        }
        return allowed;
    }
    async signinRedirectCallback() {
        let mgr = new UserManager(this.oidcSettings);
        let user = await mgr.signinRedirectCallback();
    }
}
