import * as tslib_1 from "tslib";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import moment from "moment";
import { DISCIPLINES } from "@/views/admin/CMSConstants";
const editableProps = ["title", "description", "disciplines", "tags"];
let FolderProperties = class FolderProperties extends Vue {
    constructor() {
        super();
        this.editFolder = null;
        this.searchTag = null;
        this.showImageUrl = false;
        this.disciplines = DISCIPLINES;
        this.newPermissionType = "Group";
        this.showNewPerimssion = false;
        this.showRemovePermission = false;
        this.newPermissionEmail = "";
        this.newPermissionGroup = "";
        this.emailRule = [
            v => !!v || "E-mail is required",
            v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        ];
        this.selectedPermission = {};
        this.tab = null;
        this.searchTag = null;
        this.newPermissionValid = true;
    }
    mounted() {
        this.intializeProperties(this.selectedFolder);
    }
    async saveNewPermission() {
        const formValid = this.$refs.permissionForm.validate();
        if (formValid) {
            this.$emit("addFolderPermission", this.newPermissionType === "Group"
                ? {
                    folderId: this.selectedFolder.id,
                    roleName: this.newPermissionGroup
                }
                : {
                    folderId: this.selectedFolder.id,
                    roleName: this.newPermissionEmail
                });
        }
    }
    get permissionHeaders() {
        return [
            { text: "Name", value: "roleName", sortable: false },
            { text: "", value: "id", sortable: false, width: 40 }
        ];
    }
    openRemovePermission(permission) {
        this.selectedPermission = permission;
        this.showRemovePermission = true;
    }
    async removePermission() {
        this.$emit("removePermission", this.selectedPermission.id);
    }
    intializeProperties(folderProps) {
        const imageResult = this.imageOptions.find(io => io.fileUrl === folderProps.imageUrl);
        if (imageResult != null) {
            this.showImageUrl = false;
        }
        else if (folderProps.imageUrl !== "" && folderProps.imageUrl != null) {
            this.showImageUrl = true;
        }
        else {
            this.showImageUrl = false;
        }
        this.editFolder = folderProps
            ? {
                imageUrl: folderProps.imageUrl,
                title: folderProps.title,
                description: folderProps.description,
                disciplines: folderProps.disciplines,
                tags: folderProps.tags,
                featured: folderProps.featured,
                isPublic: folderProps.isPublic
            }
            : {};
    }
    get editFolderModified() {
        let modified = false;
        if (this.selectedFolder) {
            if ((this.selectedFolder.title === null ? "" : this.selectedFolder.title) !=
                (this.editFolder.title === null ? "" : this.editFolder.title)) {
                modified = true;
            }
            else if ((this.selectedFolder.description === null
                ? ""
                : this.selectedFolder.description) !=
                (this.editFolder.description === null
                    ? ""
                    : this.editFolder.description)) {
                modified = true;
            }
            else if (this.selectedFolder.disciplines != this.editFolder.disciplines) {
                modified = true;
            }
            else if (this.selectedFolder.tags != this.editFolder.tags) {
                modified = true;
            }
        }
        return modified;
    }
    saveProperties() {
        this.$emit("updatefolder", this.editFolder);
    }
};
tslib_1.__decorate([
    Prop()
], FolderProperties.prototype, "selectedFolder", void 0);
tslib_1.__decorate([
    Prop()
], FolderProperties.prototype, "disableInput", void 0);
tslib_1.__decorate([
    Prop()
], FolderProperties.prototype, "imageOptions", void 0);
tslib_1.__decorate([
    Prop()
], FolderProperties.prototype, "permissionGroups", void 0);
FolderProperties = tslib_1.__decorate([
    Component({
        name: "FolderProperties",
        components: {},
        watch: {
            selectedFolder: function (newFolder, oldFolder) {
                if (newFolder.id !== oldFolder.id) {
                    this.tab = "folderAccessTab";
                    this.showNewPerimssion = false;
                    this.showRemovePermission = false;
                }
                this.intializeProperties(newFolder);
            },
            disableInput: function (newDisableValue) {
                if (!newDisableValue) {
                    this.showNewPerimssion = false;
                    this.showRemovePermission = false;
                    this.newPermissionEmail = "";
                    this.newPermissionGroup = "";
                }
            }
        },
        filters: {
            formatDate(date) {
                return moment(date).fromNow();
            }
        },
        computed: {
            sortedVersions: function () {
                if (this.selectedFolder.versions != null) {
                    return this.selectedFolder.versions.sort(function (v1, v2) {
                        return (new Date(v2.createdOn).getTime() - new Date(v1.createdOn).getTime());
                    });
                }
                else {
                    return [];
                }
            },
            groupPermissions: function () {
                if (this.selectedFolder != null) {
                    return this.selectedFolder.permissions.filter(function (permission) {
                        return (!permission.isLocked && !/.+@.+\..+/.test(permission.roleName));
                    });
                }
                else {
                    return [];
                }
            },
            userPermissions: function () {
                if (this.selectedFolder != null) {
                    return this.selectedFolder.permissions.filter(function (permission) {
                        return (!permission.isLocked && /.+@.+\..+/.test(permission.roleName));
                    });
                }
                else {
                    return [];
                }
            }
        }
    })
], FolderProperties);
export default FolderProperties;
