import * as tslib_1 from "tslib";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import axios from "axios";
let SearchLayout = class SearchLayout extends Vue {
    constructor() {
        super();
        this.drawer = true;
        this.searchTerms = "";
        this.searchResults = null;
        this.isSearching = false;
        this.resultHeaders = [
            {
                text: 'File Name',
                align: 'start',
                sortable: false,
                value: '_source.fileName',
            },
            {
                text: 'File Path',
                align: 'start',
                sortable: false,
                value: '_source.filePath',
            },
            {
                text: 'Uploaded By',
                align: 'start',
                sortable: false,
                value: '_source.uploadUser',
            },
            {
                text: 'File Size (bytes)',
                align: 'start',
                sortable: false,
                value: '_source.fileSizeBytes',
            },
            {
                text: 'Last Updated',
                align: 'start',
                sortable: false,
                value: '_source.updateDate',
            },
            {
                text: 'Content Type',
                align: 'start',
                sortable: false,
                value: '_source.contentType',
            },
            {
                text: 'Score',
                align: 'start',
                sortable: false,
                value: '_score',
            }
        ];
    }
    searchTermChanged(event) {
        // This will be triggered every time a new carah
    }
    async searchAileron() {
        this.isSearching = true;
        //Search term is saved at this.searchTerms
        let url = "http://search-bis-cms-pukopde3oqow3lc27uvdjbmwoe.us-east-1.es.amazonaws.com/cms/_search?q=" + this.searchTerms; // Put elasticsearch URL here
        let response = { data: {} };
        try {
            /* If we needed headers on our request
            let authHeader = {
              headers: {
                "Authorization": this.user.access_token,
                "Content-Type": "application/json"
              }
            };
            */
            // @ts-ignore
            //response = await axios.post(url, postbody, authHeader);
            //response = await axios.get(url, authHeader);
            response = await axios.get(url);
            if (response.data.hits.total.value > 0) {
                //this.searchResults = response.data.hits.hits;
                this.searchResults = response.data;
            }
            /* values will be saved at response.data */
            console.log("Response: " + JSON.stringify(response.data));
        }
        catch (error) {
            console.log("error", "Failed to call " + url + " ", error.message);
        }
        this.isSearching = false;
    }
};
SearchLayout = tslib_1.__decorate([
    Component({
        name: "SearchLayout",
        components: {}
    })
], SearchLayout);
export default SearchLayout;
