import * as tslib_1 from "tslib";
import validUrl from 'valid-url';
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { humanFileSize, mapContentTypeToIcon, mapContentTypeToIconView, mapContentTypeToTooltip } from "@/views/admin/DocumentHelpers";
import ContentManagementApiClient from "@/services/api/ContentManagementApiClient";
let FeaturedItem = class FeaturedItem extends Vue {
    constructor() {
        super();
        this.loading = false;
        this.cmsClient = new ContentManagementApiClient(process.env.VUE_APP_API_CMS_BASEURL);
    }
    async downloadFile(result) {
        if (validUrl.isUri(result.fileName)) {
            window.open(result.fileName);
        }
        else {
            this.loading = true;
            await this.cmsClient.downloadFile(result.id, result.fileName);
            this.loading = false;
        }
    }
};
FeaturedItem = tslib_1.__decorate([
    Component({
        props: ["result"],
        filters: {
            formatBytes(bytes) {
                return humanFileSize(bytes);
            },
            documentIconFilter(document) {
                return mapContentTypeToIcon(document.contentType, document.fileName);
            },
            documentIconMapping(document) {
                return mapContentTypeToIconView(document.contentType, document.fileName);
            },
            documentTooltip(document) {
                return mapContentTypeToTooltip(document.contentType, document.fileName);
            }
        }
    })
], FeaturedItem);
export default FeaturedItem;
