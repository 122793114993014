import * as tslib_1 from "tslib";
import Vue from "vue";
import { Component } from 'vue-property-decorator';
import AuthServiceFactory from '@/services/authentication/AuthServiceFactory';
import Toolbar from "@/components/core/Toolbar.vue";
let AppView = class AppView extends Vue {
    constructor() {
        super();
    }
    async loginClicked(event) {
        let authService = AuthServiceFactory.GetAuthService();
        await authService.login();
    }
};
AppView = tslib_1.__decorate([
    Component({
        components: {
            Toolbar
        }
    })
], AppView);
export default AppView;
