import Vue from 'vue';
import VueRouter from 'vue-router';
import AccessDenied from "@/views/AccessDenied.vue";
import AuthServiceFactory from '@/services/authentication/AuthServiceFactory';
import OidcCallback from "@/authentication/OIDC/OidcCallback.vue";
import OidcLogout from "@/authentication/OIDC/OidcLogout.vue";
import SilentRenew from "@/authentication/OIDC/SilentRenewOIDC.vue";
import SignInOIDCError from "@/authentication/OIDC/SignInOIDCError.vue";
/* Admin */
import FileManager from '@/views/admin/FileManager.vue';
/* Search */
import Search from "@/views/search/Search.vue";
import GuestSearch from "@/views/search/GuestSearch.vue";
import SearchResults from '@/views/search/SearchResults.vue';
import DocumentResults from '@/views/search/DocumentResults.vue';
Vue.use(VueRouter);
const routes = [
    {
        path: "/oidc-callback",
        name: "oidc-callback",
        component: OidcCallback,
        meta: {
            isOidcCallback: true,
            title: "Testing",
            isPublic: true
        }
    },
    {
        path: "/oidc-logout",
        name: "oidc-logout",
        component: OidcLogout,
        meta: {
            title: "Single Logout",
            isPublic: true
        }
    },
    {
        path: "/oidc-silent-renew",
        name: "oidc-silent-renew",
        component: SilentRenew,
        meta: {
            title: "Testing",
            isPublic: true
        }
    },
    {
        path: "/signin-oidc-error",
        name: "signin-oidc-error",
        component: SignInOIDCError,
        meta: {
            title: "Sign In Error",
            isPublic: true
        }
    },
    {
        path: "/access-denied",
        name: "access-denied",
        component: AccessDenied,
        meta: {
            title: "Access Denied",
            requiresAuth: false,
            layout: "material"
        }
    },
    {
        path: "/search",
        name: "Search",
        component: Search,
        meta: {
            title: "Search",
            requiresAuth: true,
            layout: "material"
        }
    },
    {
        path: "/guest/search",
        name: "GuestSearch",
        component: GuestSearch,
        meta: {
            title: "Guest Search",
            requiresAuth: false,
            layout: "material"
        }
    },
    {
        path: '/',
        redirect: '/search'
    },
    {
        path: "/search/results",
        name: "Search Results",
        component: SearchResults,
        meta: {
            title: "SearchResults",
            requiresAuth: false,
            layout: "material"
        },
    },
    {
        path: "/documents/:id",
        name: "Document Results",
        component: DocumentResults,
        meta: {
            name: "Document Results",
            requiresAuth: false,
            layout: "material"
        },
    },
    {
        path: "/admin/file-manager",
        name: "File Manager",
        component: FileManager,
        meta: {
            title: "File Explorer",
            requiresAuth: process.env.VUE_APP_REQUIRE_CMS_AUTH === "true",
            layout: "material"
        }
    }
];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});
router.beforeEach(async (to, from, next) => {
    let authService = AuthServiceFactory.GetAuthService();
    let requiresAuthentication = to.matched.some(record => record.meta.requiresAuth);
    if (requiresAuthentication === true) {
        if (await authService.isLoggedIn() === true) {
            let accessAllowed = await authService.accessAllowed(to.meta.roles);
            if (accessAllowed === true) {
                next();
            }
            else {
                next('/access-denied');
            }
        }
        else {
            await authService.login(to.path);
        }
    }
    else {
        next();
    }
});
export default router;
