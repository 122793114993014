import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@fortawesome/fontawesome-free/css/all.css';
Vue.use(Vuetify);
export default new Vuetify({
    icons: {
        iconfont: "fa"
    },
    theme: {
        dark: false,
        default: 'light',
        themes: {
            light: {
                primary: "#152939",
                secondary: "#55677D",
                menu: "#31465F",
                secondarymenu: "#828282",
                accent: "#8c9eff",
                error: "#b71c1c",
                primarybackground: "#F3F2F2",
            }
        }
    }
});
