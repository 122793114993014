import OidcService from "@/services/authentication/OIDC/OidcService";
export class ApiUtilities {
    static getDefaultHeaders() {
        const requestHeader = {
            'Content-Type': 'application/json',
        };
        return requestHeader;
    }
    // public static async getFormHeaders() {
    //   let authHeaders = await this.getAuthenticationHeaders();
    //   const fileHeader = {
    //     'Content-Type': 'multipart/form-data',
    //     ...authHeaders,
    //   }
    //   return fileHeader;
    // }
    static async getAuthenticationHeaders() {
        let authService = new OidcService();
        let apiUser = await authService.getUser();
        if (apiUser != null) {
            return { Authorization: "Bearer " + apiUser.access_token };
        }
    }
}
