import * as tslib_1 from "tslib";
import Vue from "vue";
import { Component } from "vue-property-decorator";
let SidebarView = class SidebarView extends Vue {
    constructor() {
        super();
        this.drawer = true;
        this.logo = process.env.VUE_APP_LOGO;
        this.applications = [
            {
                text: "User Management",
                icon: "mdi-account",
                links: [
                    {
                        to: "/user-profile",
                        icon: "mdi-account",
                        text: "User Profile"
                    },
                    {
                        to: "/table-list",
                        icon: "mdi-clipboard-outline",
                        text: "Table List"
                    },
                    {
                        to: "/typography",
                        icon: "mdi-format-font",
                        text: "Typography"
                    }
                ]
            },
            {
                text: "Accounting",
                icon: "mdi-view-dashboard",
                links: [
                    {
                        to: "/",
                        icon: "mdi-view-dashboard",
                        text: "Dashboard"
                    },
                    {
                        to: "/icons",
                        icon: "mdi-chart-bubble",
                        text: "Icons"
                    },
                    {
                        to: "/maps",
                        icon: "mdi-map-marker",
                        text: "Maps"
                    },
                    {
                        to: "/notifications",
                        icon: "mdi-bell",
                        text: "Notifications"
                    }
                ]
            }
        ];
    }
    get selectedApplicationLinks() {
        return this.applications[0].links;
    }
    signOut(event) {
        console.log('Clicked sign out: ' + JSON.stringify(event));
    }
    editProfile(event) {
        console.log('Clicked edit profile');
    }
};
SidebarView = tslib_1.__decorate([
    Component({
        components: {}
    })
], SidebarView);
export default SidebarView;
