import OidcDevelopment from "./OIDC/OidcDevelopmentService";
import Oidc from "./OIDC/OidcService";
export default class AuthServiceFactory {
    static GetAuthService() {
        let authService = null;
        let authenticationModule = process.env.VUE_APP_AUTHENTICATION_MODULE;
        switch (authenticationModule) {
            case "OidcDevelopment":
                authService = new OidcDevelopment();
                break;
            case "Oidc":
                authService = new Oidc();
                break;
            default:
                throw new Error("Authentication module " + authenticationModule + " is undefined.");
        }
        return authService;
    }
}
