import * as tslib_1 from "tslib";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import OidcService from "@/services/authentication/OIDC/OidcService";
let OidcLogout = class OidcLogout extends Vue {
    constructor() {
        super();
        this.authService = new OidcService();
    }
    async mounted() {
        try {
            console.log("Received logout request from the identity server");
            await this.authService.logout();
        }
        catch (error) {
            console.log("Error in oidc logout: " + error.message);
        }
    }
};
OidcLogout = tslib_1.__decorate([
    Component({
        name: "OidcLogout",
        computed: {}
    })
], OidcLogout);
export default OidcLogout;
