import * as tslib_1 from "tslib";
import Vue from 'vue';
import { Component } from "vue-property-decorator";
let SignInOIDCErrorView = class SignInOIDCErrorView extends Vue {
    constructor() {
        super();
    }
    mounted() {
    }
};
SignInOIDCErrorView = tslib_1.__decorate([
    Component({
        name: "AileronDrawer",
        computed: {}
    })
], SignInOIDCErrorView);
export default SignInOIDCErrorView;
