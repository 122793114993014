import * as tslib_1 from "tslib";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import OidcService from "@/services/authentication/OIDC/OidcService";
import { Mutation } from "vuex-class";
import { getCookie } from "@/services/CookieHelpers";
let OidcCallbackView = class OidcCallbackView extends Vue {
    constructor() {
        super();
        this.authService = new OidcService();
        this.user = null;
    }
    async mounted() {
        try {
            this.user = await this.authService.getUser();
            //this.setUser(this.user);
            await this.authService.signinRedirectCallback();
            const cookieAttemptedPath = getCookie("attemptedPath");
            if (this.$route.params.attemptedPath) {
                console.log("Rerouting to attempted path");
                this.$router.push(this.$route.params.attemptedPath);
            }
            else if (cookieAttemptedPath) {
                console.log("Rerouting to attempted cookie path");
                this.$router.push(cookieAttemptedPath);
            }
            else {
                this.$router.push("/");
            }
        }
        catch (error) {
            console.log("Error in oidc callback: " + error.message);
        }
    }
};
tslib_1.__decorate([
    Mutation("setUser")
], OidcCallbackView.prototype, "setUser", void 0);
OidcCallbackView = tslib_1.__decorate([
    Component({
        name: "OidcCallback",
        computed: {}
    })
], OidcCallbackView);
export default OidcCallbackView;
